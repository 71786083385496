import React from "react"
import Page from "../../components/page"
import TextLeft from "../../components/textLeft"
import { graphql } from "gatsby"

export default function DebossingCustomDies({ location, data }) {
  return (
    <Page title={"Custom dies – PhotoTailors"} location={location}>
      <TextLeft
        title={<div>Custom<br/>Dies </div>}
        subtitle="tailored for you"
        text={
          <div>
            <p>If you have a custom logo or graphic we can transform this into a custom die in order to emboss the
              cover. Once we have the custom die we can use it as many times as needed without any additional costs. It
              can be used without foil (blind emboss) or using gold, silver or cream foil.</p>
            <p>&nbsp;</p>
            <p>The price for custom dies depends on the die area. It costs $8 per square inch or $1.2 per cm2.</p>
            <p>If you have any questions do not hesitate to contact us.</p>

          </div>
        }
        data={data}
        image={"debossingCustomDies"}
      />

    </Page>
  )
}


export const query = graphql`
  query {
    debossingCustomDies: file(relativePath: { eq: "debossingCustomDies.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    } 
    
  }
`